export const hotel = [
  {
    id: 0,
    logo: `${process.env.PUBLIC_URL}/assets/img/id_0/logo_hotel.svg`,
    name: 'Litworowy Staw',
    phone: '+48 799 566 560',
    image: [
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-1.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-3.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-4.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-6.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-7.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-8.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-9.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-10.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-11.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-12.jpg`,
    ],
    image_room: [`${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-11.jpg`],
    title: 'O nas',
    description:
      'O nas z JSON Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus odio congue arcu molestie sodales. Sed morbi mauris cras sem amet. In ullamcorper tellus tincidunt lectus porttitor imperdiet sit sed. Sit tempor pulvinar pellentesque amet ut. Senectus egestas tortor diam bibendum nisi, tortor duis imperdiet varius. Vitae diam sit eget odio id amet. Mollis ullamcorper nec bibendum tortor, et. Sed ac, quis volutpat leo vulputate sit odio imperdiet. Nascetur duis proin consequat non amet. Dolor lacus, erat cras id in arcu non. Ornare id vulputate eget curabitur varius fusce eget metus elit. Faucibus felis habitant fermentum molestie aenean eget cras. Vulputate rhoncus nec pretium at nunc maecenas aliquam leo posuere. Morbi pretium tempus diam mattis.',
    title_secondary: 'Dlaczego My',
    description_secondary:
      'Dlaczego My z JSONLorem ipsum dolor sit amet, consectetur adipiscing elit. Risus odio congue arcu molestie sodales. Sed morbi mauris cras sem amet. In ullamcorper tellus tincidunt lectus porttitor imperdiet sit sed. Sit tempor pulvinar pellentesque amet ut. Senectus egestas tortor diam bibendum nisi, tortor duis imperdiet varius. Vitae diam sit eget odio id amet. Mollis ullamcorper nec bibendum tortor, et. Sed ac, quis volutpat leo vulputate sit odio imperdiet. Nascetur duis proin consequat non amet. Dolor lacus, erat cras id in arcu non. Ornare id vulputate eget curabitur varius fusce eget metus elit. Faucibus felis habitant fermentum molestie aenean eget cras. Vulputate rhoncus nec pretium at nunc maecenas aliquam leo posuere. Morbi pretium tempus diam mattis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus odio congue arcu molestie sodales. Sed morbi mauris cras sem amet. In ullamcorper tellus tincidunt lectus porttitor imperdiet sit sed. Sit tempor pulvinar pellentesque amet ut. Senectus egestas tortor diam bibendum nisi, tortor duis imperdiet varius. Vitae diam sit eget odio id amet. Mollis ullamcorper nec bibendum tortor, et. Sed ac, quis volutpat leo vulputate sit odio imperdiet. Nascetur duis proin consequat non amet. Dolor lacus, erat cras id in arcu non. Ornare id vulputate eget curabitur varius fusce eget metus elit. Faucibus felis habitant fermentum molestie aenean eget cras. Vulputate rhoncus nec pretium at nunc maecenas aliquam leo posuere. Morbi pretium tempus diam mattis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus odio congue arcu molestie sodales. Sed morbi mauris cras sem amet. In ullamcorper tellus tincidunt lectus porttitor imperdiet sit sed. Sit tempor pulvinar pellentesque amet ut. Senectus egestas tortor diam bibendum nisi, tortor duis ',
    room: 'Pokoje',
    room_image: [
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-10.jpg`,
      `${process.env.PUBLIC_URL}/assets/img/id_0/ds-kaniowka-11.jpg`,
    ],
    room_description:
      'Room z JSON Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus odio congue arcu molestie sodales. Sed morbi mauris cras sem amet. In ullamcorper tellus tincidunt lectus porttitor imperdiet sit sed. Sit tempor pulvinar pellentesque amet ut. Senectus egestas tortor diam bibendum nisi, tortor duis imperdiet varius. Vitae diam sit eget odio id amet. Mollis ullamcorper nec bibendum tortor, et. Sed ac, quis volutpat leo vulputate sit odio imperdiet. Nascetur duis proin consequat non amet. Dolor lacus, erat cras id in arcu non. Ornare id vulputate eget curabitur varius fusce eget metus elit.',
  },
];
