import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { hotel } from '../helpers/hotel';

import './App.scss';
import GlobalStyle from '../theme/GlobalStyle';

const Header = React.lazy(() => import('../components/header/header'));
const Navigation = React.lazy(() => import('../components/navigation/navigation'));
const NewsPage = React.lazy(() => import('./news/newsPage'));
const AboutPage = React.lazy(() => import('./about/aboutPage'));
const OffersPage = React.lazy(() => import('./offers/offersPage'));

export interface HotelContextData {
  aboutHotel: any;
  loading: boolean;
}

export const hotelContextDefaultValue: HotelContextData = {
  aboutHotel: [],
  loading: false,
};

export const HotelContext = React.createContext<HotelContextData>(hotelContextDefaultValue);

const App = () => {
  const { i18n } = useTranslation();
  const [languagePl, setLanguagePl] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguagePl(!languagePl);
  };

  const [aboutHotel, setAboutHotel] = useState({});
  const [loading, setLoading] = useState(false);
  const fetch = () => {
    setLoading(true);
    setAboutHotel(hotel[0]);
    setLoading(false);
  };
  useEffect(() => {
    fetch();
  }, []);

  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) setTransitionStage('fadeOut');
  }, [location]);

  const [showButtonBack, setShowButtonBack] = useState(false);
  const toggleShowButton = (param: boolean) => {
    setShowButtonBack(param);
  };

  const [searchBar, setSearchBar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 1) {
      setSearchBar(true);
    } else {
      setSearchBar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  return (
    <div className="app">
      <GlobalStyle />
      <HotelContext.Provider value={{ aboutHotel, loading }}>
        <Header
          showButtonBack={showButtonBack}
          toggleShowButton={toggleShowButton}
          searchBar={searchBar}
        />
        <div
          className={`${transitionStage}`}
          onAnimationEnd={() => {
            if (transitionStage === 'fadeOut') {
              setTransitionStage('fadeIn');
              setDisplayLocation(location);
            }
          }}
        >
          <Switch location={displayLocation}>
            <Route exact path="/">
              <AboutPage searchBar={searchBar} />
            </Route>
            <Route path="/news">
              <NewsPage />
            </Route>
            <Route path="/offers">
              <OffersPage toggleShowButton={toggleShowButton} searchBar={searchBar} />
            </Route>
          </Switch>
        </div>
        <Navigation toggleShowButton={toggleShowButton} />
      </HotelContext.Provider>
    </div>
  );
};

export default App;
